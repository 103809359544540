import React from 'react';
import ModelTable from "./ModelTable/ModelTable";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


const ResultsSummary = (props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {!props.results.is_pending &&
                <Typography variant='h5'>
                    Results are {props.results.is_rejected
                    ? <span style={{"color": "#ff0000"}}>rejected</span>
                    : <span style={{"color": "#009800"}}>approved</span>} already
                </Typography>
                }
            </Grid>
            {props.results.data.map((tableData, index) => {
                return (<Grid item xs={12} sm={6} key={index}>
                    <ModelTable idx={index+1} tableData={tableData}/>
                </Grid>)
            }
            )}
        </Grid>
    );
};

export default ResultsSummary;
