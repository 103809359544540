import React, {Component} from 'react';
import ResultsSummary from "../../components/ResultsSummary/ResultsSummary";
import Button from "@material-ui/core/Button";
import axios from "../../util/AxiosWithAuth";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import './Results.css'
import PatientSelect from "../../components/PatientSelect/PatientSelect";
import {getFullName, getPatientOptions, getPatientValue, onPatientSelected, userAcceptedTerms} from "../../util/common";
import {Redirect, withRouter} from "react-router-dom";


class Results extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            noResults: false,
            results: this.props.location.state ? this.props.location.state.results : null,
            accepted: false,
            patient: this.props.location.state ? this.props.location.state.patient : null,
            patients: [],
        };
        this.getPatientValue = getPatientValue.bind(this);
        this.getPatientOptions = getPatientOptions.bind(this);
        this.onPatientSelected = onPatientSelected.bind(this)
    }

    componentDidMount() {
        if (!this.state.results && this.state.patient) {
            this.loadResults(this.state.patient.id)
        }
        this.loadPatients();
    }

    loadPatients= () => {
        axios.get(`/api/patients/`)
            .then(response => {
                this.setState({patients: response.data})
            })
    };

    loadResults = (patientId) => {
        this.setState({isLoading: true});
        axios.get('/api/results/', {
            params: {patient_id: patientId}
        })
            .then(response => {
                if (response.status === 200)  {this.setState({results: response.data, isLoading: false, noResults: false})}
            })
            .catch(error => {
                this.setState({isLoading: false, noResults: true, results: null})
            })
    };

    onSelected = (selected) => {
        this.onPatientSelected(selected);
        this.loadResults(selected.value);
    };

    onApprove = () => {
        this.setState({isLoading: true});
        axios.get('/api/results/accept/', {
            params: {patient_id: this.state.patient.id}
        })
            .then(response => {
                if (response.status === 200)  {
                    this.setState({accepted: true, isLoading: false});
                    setTimeout(this.props.history.push, 5000, {pathname: '/patients'})
                }
            })
            .catch(error => {
                this.setState({isLoading: false})
            })
    };

    onReject = () => {
        this.setState({isLoading: true});
        axios.get('/api/results/reject/', {
            params: {patient_id: this.state.patient.id}
        })
            .then(response => {
                if (response.status === 200)  {this.props.history.push({pathname: '/patients'})}
            })
            .catch(error => {
                this.setState({isLoading: false})
            })
    };

    onNewCase = () => {
        this.props.history.push({pathname: '/patients'})
    };

    onEditPhotos = () => {
        this.setState({isLoading: true});
        axios.get('/api/results/edit/', {
            params: {patient_id: this.state.patient.id}
        })
            .then(response => {
                if (response.status === 200)  {this.props.history.push({pathname: '/photos', state: {patient: this.state.patient}})}
            })
    };

    render() {
        if (this.props.user && !userAcceptedTerms(this.props.user)) {
            return <Redirect to='/terms'/>
        }
        const steps = ['Today', '12 months', '18 months'];
        const resultsAcceptedBlock = (
            <div>
                <Typography variant='h3'>Thank you!</Typography>
                <Typography variant='subtitle1'>The information will be sent to you. Please check your e-mail.</Typography>
            </div>
        )

        let noResultsBlock = null;

        if (this.state.noResults) {
            noResultsBlock = (
                <div>
                    <Typography variant='h4'>Not found</Typography>
                    <hr/>
                    <Typography variant='subtitle1'>There are no results for patient {getFullName(this.state.patient)}</Typography>
                </div>
            )
        }


        return (
            <div>
                <PatientSelect getPatientValue={this.getPatientValue}
                               getPatientOptions={this.getPatientOptions}
                               onPatientSelected={this.onSelected}
                />
                {this.state.isLoading
                    ? <CircularProgress className='photosUpload-spinner' size={75}/>
                    :
                    <div>
                        {this.state.accepted
                            ? resultsAcceptedBlock
                            : this.state.results &&
                            <div>
                                <Typography variant='h3'>Suggested procedure</Typography>
                                <Stepper alternativeLabel activeStep={3} style={{maxWidth: '750px', margin: "auto", backgroundColor: "inherit"}}>
                                    {steps.map(label => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Grid container spacing={2} style={{width: '100vw'}}>
                                    <Grid item md={3}/>
                                    <Grid item xs={12} sm={12} md={6}>
                                        {this.state.results ? <ResultsSummary results={this.state.results}/> : null}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={2}>
                                        <div className="results-buttons-block">
                                            <Button variant="contained"
                                                    color="primary"
                                                    className="results-button"
                                                    disabled={!this.state.results.is_pending}
                                                    onClick={this.onApprove}>Approve</Button>
                                            <Button variant="contained"
                                                    color="secondary"
                                                    className="results-button"
                                                    disabled={!this.state.results.is_pending}
                                                    onClick={this.onReject}>Reject</Button>
                                            <Button variant="contained"
                                                    color="default"
                                                    className="results-button"
                                                    onClick={this.onEditPhotos}>Edit photos</Button>
                                            <Button variant="contained"
                                                    color="primary"
                                                    className="results-button"
                                                    onClick={this.onNewCase}>New Case</Button>
                                        </div>
                                    </Grid>
                                    <Grid item md={1}/>
                                </Grid>
                            </div>
                        }
                    </div>
                }
                {noResultsBlock}
            </div>
        );
    }
}

export default withRouter(Results);
