import axios from 'axios';

const instance = axios.create({
});

instance.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('dots_token');
        if (token) {
            config.headers.Authorization = `Token ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

export default instance;
