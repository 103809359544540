import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import './Snackbar.css'


const notification = (props) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        props.closeSnackbar ();
    };

    return (
        <Snackbar anchorOrigin={{vertical: 'bottom',horizontal: 'right',}}
                  open={!!props.error}
                  autoHideDuration={3000}
                  onClose={() => handleClose()}
        >
            <SnackbarContent
                className="notification-error"
                message={
                    <span id="message-id" className="notification__content">
                        <ErrorIcon  className="notification__icon"/>
                        {props.message}
                    </span>
                }
                action={[
                    <IconButton key="close"
                                aria-label="close"
                                color="inherit"
                                onClick={() => handleClose()}>
                        <CloseIcon/>
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

export default notification;
