import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import axios from "../../util/AxiosWithAuth"
import PersonalInfo from "../../components/PersonalInfo/PersonalInfo";


class TermsOfService extends Component {
    constructor(props) {
        super (props);
        this.state = {
            isAccepted: props.user ? props.user.terms_accepted : false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            this.setState({isAccepted: this.props.user.terms_accepted})
        }
    }

    handleCheckbox = (checked) => {
        this.setState({isAccepted : checked})
    };

    onAccept = (e) => {
        e.preventDefault();
        axios.post(`/api/user/`, {...this.props.user, terms_accepted: this.state.isAccepted})
            .then(response => {
                if (response.status === 200) {
                    this.props.updateUser(response.data);
                    this.props.history.push('/profile')
                }
            })
    };

    render() {
        return (
            <div className="profile-div-wrapper" style={{maxWidth: '700px'}}>
                <form onSubmit={(e) => this.onAccept(e)}>
                    {this.props.user ? <PersonalInfo user={this.props.user} userChange={this.props.userChange}/> : null}
                    <div style={{marginTop: '20px'}}>
                        <h4>Terms and Conditions:</h4>
                        <p>
                            This is a private version. You must not share your credentials.
                            All the data will be deleted at your request.
                        </p>
                        <p>
                            The use of this tool and of the aid provided represent only an advice to the orthodontist (the
                            designed user). The diagnosis, the treatment planning and the use of orthodontic devices are
                            considered an exclusive expertise and responsibility of the orthodontist. Therefore the
                            orthodontist using this tool gives up any claim concerning unsatisfactory results.
                        </p>
                        <p>
                            By pressing the “Accept” button, the orthodontist confirm understanding as much stated
                            above, all terms and conditions of use of the software and the non-disclosure policy.
                        </p>
                    </div>
                    <hr/>
                    <FormControlLabel label="I agree"
                                      control={
                                          <Checkbox checked={this.state.isAccepted}
                                                    onChange={(event, checked) => this.handleCheckbox(checked)}
                                                    value="checkedB"
                                                    color="primary"/>
                                      }
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!this.state.isAccepted}
                        type="submit"
                    >
                        Accept
                    </Button>
                </form>

            </div>
        );
    }
}

export default withRouter(TermsOfService);
