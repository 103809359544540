import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const columns = [
    { id: 'id', label: 'ID', minWidth: 30 },
    { id: 'name', label: 'Name', minWidth: 120 },
    { id: 'surname', label: 'Surname', minWidth: 120 },
    { id: 'date', label: 'Date', minWidth: 100 },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {
        maxHeight: 700,
        overflow: 'auto',
    },
});

export default function PatientsTable(props) {
    const classes = useStyles();

    const rows = props.patients.map((patient, index) => {
        const id = index + 1;
        const name = patient.first_name;
        const surname = patient.last_name;
        const date = new Date(patient.created).toDateString();
        const value = patient.id;
        return {id, name,  surname, date, value};
    })

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(column => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.value} onClick={() => props.onPatientSelected(row)}>
                                    {columns.map(column => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
}
