import React from 'react';
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

const ContactLink = ( props ) => (
    <li className='HeaderLink' style={{marginRight: '30px'}}>
        <Button className="header-button">
            <Link href={props.link}>
                {props.children}
            </Link>
        </Button>
    </li>
);

export default ContactLink;
