import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import axios from "../../util/AxiosWithAuth"
import TermsOfService from "../TermsOfService/TermsOfService";
import PhotosUpload from "../PhotosUpload/PhotosUpload";
import PhotoEdit from "../PhotoEdit/PhotoEdit";
import Results from "../Results/Results";
import Logout from "../Auth/Logout/Logout";
import Layout from "../../hoc/Layout/Layout";
import withErrorHandler from "../../hoc/withErrorHandler/withErrorHandler";
import {onUserFieldChange} from "../../util/common";
import UserProfile from "../Profile/UserProfile";
import Patients from "../Patients/Patients";


class Main extends Component {
    constructor(props) {
        super (props);
        this.state = {
            user: null,
        }
        this.onUserFieldChange = onUserFieldChange.bind(this);
    }

    componentDidMount() {
        this.loadPersonalInfo()
    }

    loadPersonalInfo = () => {
        axios.get('/api/user/')
            .then(response => {
                this.setState({user: response.data})
            })
    };

    updateUser = (updatedUser) => {
        this.setState({user: updatedUser})
    }

    render() {
        return (
                <Layout>
                    <Switch>
                        <Route path='/terms' render={() => <TermsOfService user={this.state.user}
                                                                           userChange={this.onUserFieldChange}
                                                                           updateUser={this.updateUser}/>}/>
                        <Route path='/profile' render={() => <UserProfile user={this.state.user}
                                                                          userChange={this.onUserFieldChange}
                                                                          updateUser={this.updateUser}/>}/>
                        <Route path='/patients' render={() => <Patients user={this.state.user}/>}/>
                        <Route path='/photos/edit/:type(front|side|superior|inferior)'
                               render={({match, history}) => <PhotoEdit match={match}
                                                                        history={history}
                                                                        user={this.state.user}/>}/>
                        <Route path='/photos' exact render={() => <PhotosUpload user={this.state.user}/>}/>
                        <Route path='/results' render={() => <Results user={this.state.user}/>}/>
                        <Route path='/logout' component={Logout}/>
                        <Redirect to='/profile'/>
                    </Switch>
                </Layout>
        );
    }
}

export default withErrorHandler(withRouter(Main), axios);
