import React from 'react';
import { NavLink } from 'react-router-dom';

import './HeaderLink.css';
import Button from "@material-ui/core/Button";

const HeaderLink = ( props ) => (
    <li className='HeaderLink'>
        <Button className="header-button">
        <NavLink
            to={props.link}
            exact={props.exact}
            activeClassName='active'>{props.children}</NavLink>
        </Button>
    </li>
);

export default HeaderLink;
