export class Auth {

    static isLogged() {
        return sessionStorage.getItem('dots_token') != null;
    }

    static saveCredentials(token) {
        sessionStorage.setItem('dots_token', token);
    }

    static withAuth() {
        return {'Authorization': 'Token ' + sessionStorage.getItem('dots_token')};
    }

    static logout() {
        sessionStorage.removeItem('dots_token');
    }
}
