import React, { Component } from 'react';
import Notification from '../../components/UI/Snackbar/Snackbar'


const withErrorHandler = ( WrappedComponent, axios ) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                error: null
            };

            this.reqInterceptor = axios.interceptors.request.use( req => {
                this.setState( { error: null } );
                return req;
            }, error => Promise.reject(error) );
            this.resInterceptor = axios.interceptors.response.use( res => res, error => {
                this.setState( { error: error } );
                return Promise.reject(error)
            } );
        }

        componentWillUnmount () {
            axios.interceptors.request.eject( this.reqInterceptor );
            axios.interceptors.response.eject( this.resInterceptor );
        }

        closeSnackbar = () => {
            this.setState( { error: null } );
        }

        formatError = (error) => {
            if (Object.keys(error).includes("framesToPop")) {
                return error.name
            }
            if (!error.response) {
                return error.message
            }
            let error_text = error.response.statusText + ': ';
            if (error.response.data.detail !== undefined) {
                error_text += error.response.data.detail
            } else if (error.response.data.error !== undefined) {
                error_text += error.response.data.error
            } else if (error.response.data !== undefined) {
                error_text += error.response.data
            }
            if (error.response.status === 500) {
                error_text = 'The server was unable to complete your request. Please try again later'
            }
            return error_text
        }

        render () {
            const message = this.state.error ? this.formatError(this.state.error) : '';
            return (
                <>
                    <Notification closeSnackbar={this.closeSnackbar} error={this.state.error} message={message}/>
                    <WrappedComponent {...this.props} />
                </>
            );
        }
    }
}

export default withErrorHandler;
