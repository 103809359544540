import React from 'react';
import PersonalDetail from "./PersonalDetail/PersonalDetail";
import Grid from "@material-ui/core/Grid";
import {detailLabel} from "../../util/common";


const PersonalDetails = (props) => {
    return (
        <Grid container>
            {props.user && Object.keys(props.user)
                .filter(detail => Object.keys(detailLabel).includes(detail))
                .map((detail, key) => {
                    return (
                        <React.Fragment key={key}>
                            <Grid item xs={12} sm={props.isGroup ? 6 : 12}>
                                <PersonalDetail detail={detail}
                                                value={props.user[detail]}
                                                userChange={props.userChange}
                                                key={detail}
                                />
                            </Grid>
                            {detail === 'age' && <Grid item xs={12} sm={12}/>}
                        </React.Fragment>
                    )
                }
            )}
        </Grid>


    );
}

export default PersonalDetails;
