import React, {Component} from 'react';
import defaultPhotoConfig from "../../components/Photos/Photo/defaultPhotoConfig";
import axios from "../../util/AxiosWithAuth";
import Button from "@material-ui/core/Button";
import PhotoSketchField from "./PhotoSketchField";
import Grid from "@material-ui/core/Grid";
import "./PhotoEdit.css"
import {Redirect, withRouter} from "react-router-dom";
import {userAcceptedTerms} from "../../util/common";


const STEPS_REQUIRED = {
    front: 12,
    side: 12,
    superior: 11,
    inferior: 11,
};


class PhotoEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photo: null,
            step: 0,
            markersCount: 0,
        }
        this.patient = props.location.state ? props.location.state.patient : null;
    }

    photoType = this.props.match.params.type;

    componentDidMount() {
        if (userAcceptedTerms(this.props.user) && this.patient) {
            this.loadPhoto();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.user !== prevProps.user && userAcceptedTerms(this.props.user) && this.patient){
            this.loadPhoto();
        }
    }

    loadPhoto = () => {
        axios.get(`/api/photo/${this.photoType}/`, {
            params: {patient_id: this.patient.id}
        })
            .then(response => {
                const markersCount = Object.values(response.data.markers).length;
                this.setState({
                    photo: response.data,
                    step: markersCount,
                    markersCount: markersCount})
            })
    }

    incrementStep = () => {
        this.setState((prevState) => {
            return {step: prevState.step + 1}
        })
    }

    removeLastMarker = () => {
        const updatedPhoto = {...this.state.photo};
        delete updatedPhoto.markers[this.state.markersCount];
        if (this.state.step === this.state.markersCount) {
            this.setState((prevState) => {
                return {step: prevState.step - 1, markersCount: prevState.markersCount - 1}
            })

        } else {
             this.setState((prevState) => {
                return {markersCount: prevState.markersCount - 1}
            })
        }
    }

    resetMarkers = () => {
        const updatedPhoto = {...this.state.photo};
        updatedPhoto.markers = {};
        this.setState( {step: 0, markersCount: 0, photo: updatedPhoto})
    }

    onSave = () => {
        if (Object.values(this.state.photo.markers).length === STEPS_REQUIRED[this.photoType]) {
            axios.post(`/api/photo/${this.photoType}/`, {
                markers: this.state.photo.markers,
                patient_id: this.patient.id,
            }).then(response => {
                if (response.status === 200) {
                    this.props.history.push({pathname: '/photos', state: {patient: this.patient}})
                }
            })
        }
    }

    setMarkerState = (x, y) => {
        const updatedPhoto = {...this.state.photo};
        updatedPhoto.markers[this.state.markersCount + 1] = {x: x, y: y};
        this.setState((prevState) => {return {photo: updatedPhoto, markersCount: prevState.markersCount + 1}})
    }

    render() {
        if (this.props.user && !userAcceptedTerms(this.props.user)) {
            return <Redirect to='/terms'/>
        }
        if (!this.patient) {
            return <Redirect to='/photos'/>
        }

        let previousButton = null;
        if (0 < this.state.step && this.state.step < STEPS_REQUIRED[this.photoType]) {
            previousButton = <Button variant="contained"
                                     color="secondary"
                                     className="buttonsPhotoEdit"
                                     onClick={this.removeLastMarker}>Previous</Button>
        }

        let nextButton = null;
        if (0 <= this.state.step && this.state.step < STEPS_REQUIRED[this.photoType]) {
            nextButton = <Button variant="contained"
                                 color="primary"
                                 className="buttonsPhotoEdit"
                                 disabled={this.state.step === this.state.markersCount}
                                 onClick={this.incrementStep}>Next</Button>
        }

        let editButton = null;
        if (this.state.step === STEPS_REQUIRED[this.photoType]) {
            editButton = <Button variant="contained"
                                 color="secondary"
                                 className="buttonsPhotoProceed"
                                 onClick={this.resetMarkers}>Edit</Button>
        }

        let saveButton = null;
        if (this.state.step === STEPS_REQUIRED[this.photoType]) {
            saveButton = <Button variant="contained"
                                 color="primary"
                                 className="buttonsPhotoProceed"
                                 onClick={this.onSave}>SAVE</Button>
        }

        const path = defaultPhotoConfig[this.photoType];
        const defaultPhotoPath = path + (this.state.step + (this.state.step < STEPS_REQUIRED[this.photoType])) + '.png';

        return (
            <div>
                <Grid container spacing={0} alignItems="flex-start" justify="center">
                    <Grid container item xs={12} sm={12} md={6} justify="center">
                        <Grid item xs={12}>
                        <PhotoSketchField key={this.state.photo ? this.state.photo.url : ''}
                                          setMarkerState={this.setMarkerState} markersCount={this.state.markersCount}
                                          photo={this.state.photo} step={this.state.step} maxSteps={STEPS_REQUIRED[this.photoType]}/>
                        </Grid>
                        <div style={{display: "flex", minWidth: '250px', width: '100%', margin: '10px 0'}}>
                            <Grid item xs={12} sm={6}>
                                {previousButton}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            {nextButton}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <img height={this.state.photo ? this.state.photo.height : 600}
                             src={defaultPhotoPath} alt='example' className="edit-photo-border"/>
                    </Grid>
                </Grid>
                <div className='buttonsPhotoProceedBlock'>
                    {editButton}
                    {saveButton}
                </div>
            </div>
        );
    }
}

export default withRouter(PhotoEdit);
