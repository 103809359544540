import React, {Component} from 'react';
import axios from "../../util/AxiosWithAuth"
import Button from "@material-ui/core/Button";
import {withRouter} from "react-router-dom";
import './UserProfile.css'
import PersonalInfo from "../../components/PersonalInfo/PersonalInfo";


class UserProfile extends Component {
    saveProfile = (e) => {
        e.preventDefault();
        axios.post(`/api/user/`,{...this.props.user})
            .then(response => {
                if (response.status === 200) {
                    this.props.updateUser(response.data)
                }
            })
    };

    render() {
        return (
            <div className='profile-div-wrapper'>
                <form onSubmit={(e) => this.saveProfile(e)} style={{width: '500px'}}>
                    {this.props.user &&
                    <>
                        <PersonalInfo user={this.props.user} userChange={this.props.userChange}/>
                        <Button variant="contained"
                                color="primary"
                                fullWidth
                                className="profile-button-wrapper"
                                type="submit">
                            Save
                        </Button>
                    </>
                    }
                </form>
            </div>
        );
    }
}

export default withRouter(UserProfile);
