import React from 'react';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import './PersonalInfo.css'
import PersonalDetails from "../PersonalDetails/PersonalDetails";


const PersonalInfo = (props) => {
    return (
        props.user &&
            <Paper elevation={2} className="personalInfo-paper">
                <Typography component="h2" className="personalInfo-title">
                    {props.fullName ? props.fullName : 'Your personal details'}
                </Typography>
                <PersonalDetails user={props.user}
                                 userChange={props.userChange}
                                 isGroup={props.isGroup}
                />
            </Paper>
    );
};

export default PersonalInfo;
