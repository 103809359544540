import React from 'react';
import {Auth} from "../../../util/Auth";
import {Redirect, withRouter} from "react-router-dom";


const Logout = (props) => {
    if (Auth.isLogged()) {
        Auth.logout();
    }
    return (
        <Redirect to={'/login'}/>
    );
}

export default withRouter(Logout);
