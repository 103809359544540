import React, {Component} from 'react';
import axios from "../../util/AxiosWithAuth";
import {
    getFullName,
    onPatientSelected,
    onPatientFieldChange,
    newPatient, userAcceptedTerms
} from "../../util/common";
import PersonalInfo from "../../components/PersonalInfo/PersonalInfo";
import Button from "@material-ui/core/Button";
import {Redirect, withRouter} from "react-router-dom";
import PatientsTable from "../../components/PatientsTable/PatientsTable";
import Grid from "@material-ui/core/Grid";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";



class Patients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            patient: null,
            dialogOpen: false
        }
        this.onPatientFieldChange = onPatientFieldChange.bind(this);
        this.onPatientSelected = onPatientSelected.bind(this)
    }

    componentDidMount() {
        this.loadPatients();
    }

    loadPatients= () => {
        axios.get(`/api/patients/`)
            .then(response => {
                this.setState({patients: response.data})
            })
    };

    savePatientInfo = (e) => {
        e.preventDefault();
        axios.post(`/api/patients/${this.state.patient.id}/`, {...this.state.patient})
            .then(response => {
                const patient = response.data;
                const updatedPatients = this.state.patients.filter(prevPatient => prevPatient.id !== patient.id);
                updatedPatients.push(patient);
                updatedPatients.sort( (a, b) => a.id - b.id);
                this.setState({patient: null, patients: updatedPatients})
            })
    }

    onManagePhotos = () => {
        this.props.history.push({pathname: '/photos', state: {patient: this.state.patient}})
    };

    onShowResults = () => {
        this.props.history.push({pathname: '/results', state: {patient: this.state.patient}})
    };

    onBack = () => {
        this.setState({patient: null})
    };

    onAddPatient = () => {
        this.setState({patient: newPatient})
    }

    onDialogOpen = () => {
        this.setState({dialogOpen: true})
    }

    onDialogClose = () => {
        this.setState({dialogOpen: false})
    }

    onDelete = () => {
        axios.delete(`/api/patients/${this.state.patient.id}/`)
            .then(response => {
                const updatedPatients = this.state.patients.filter(patient => patient.id !== this.state.patient.id);
                this.setState({patient: null, patients: updatedPatients, dialogOpen: false})

            })
    }

    render() {
        if (this.props.user && !userAcceptedTerms(this.props.user)) {
            return <Redirect to='/terms'/>
        }
        return (
            <div className='patients-div-wrapper'>
                {!this.state.patient &&
                    <>
                        <PatientsTable patients={this.state.patients} onPatientSelected={this.onPatientSelected}/>
                        <Button variant="contained"
                            color="primary"
                            fullWidth
                            onClick={this.onAddPatient}>
                            Add new patient
                        </Button>
                    </>
                }
                {this.state.patient &&
                <>
                    <form onSubmit={(e) => this.savePatientInfo(e)} style={{width: '500px'}}>
                        <PersonalInfo user={this.state.patient} isGroup={true}
                                      userChange={this.onPatientFieldChange}
                                      fullName={getFullName(this.state.patient)}
                        />
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Button variant="contained"
                                        color="default"
                                        fullWidth
                                        onClick={this.onBack}>
                                    Back
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant="contained"
                                        style={{backgroundColor: "#ff3a3f"}}
                                        fullWidth
                                        disabled={!this.state.patient.id}
                                        onClick={this.onDialogOpen}>
                                    Delete
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button variant="contained"
                                        style={{backgroundColor: "#57f046"}}
                                        fullWidth
                                        type="submit">
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained"
                                        color="primary"
                                        fullWidth
                                        className="profile-button-wrapper"
                                        disabled={!this.state.patient.id}
                                        onClick={this.onManagePhotos}>
                                    Start analysis
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button variant="contained"
                                        color="primary"
                                        fullWidth
                                        className="profile-button-wrapper"
                                        disabled={!this.state.patient.id}
                                        onClick={this.onShowResults}>
                                    Show Results
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </>
                }
                <DeleteDialog open={this.state.dialogOpen}
                              name={this.state.patient && getFullName(this.state.patient)}
                              handleClose={this.onDialogClose}
                              onDelete={this.onDelete}/>
            </div>
        );
    }
}

export default withRouter(Patients);
