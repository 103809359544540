import React, {Component} from 'react';
import axios from "../../util/AxiosWithAuth";
import Button from "@material-ui/core/Button";
import Photos from "../../components/Photos/Photos";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Redirect, withRouter} from "react-router-dom";
import {getPatientOptions, getPatientValue, onPatientSelected, userAcceptedTerms} from "../../util/common";
import './PhotosUpload.css'
import PatientSelect from "../../components/PatientSelect/PatientSelect";


class PhotosUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            photos: [{'photo_type': 'front'}, {'photo_type': 'side'}, {'photo_type': 'superior'}, {'photo_type': 'inferior'},],
            gotResults: false,
            results: {},
            patient: this.props.location.state ? this.props.location.state.patient : null,
            patients: [],
        }
        this.getPatientValue = getPatientValue.bind(this);
        this.getPatientOptions = getPatientOptions.bind(this);
        this.onPatientSelected = onPatientSelected.bind(this)
    }

    componentDidMount() {
        if (userAcceptedTerms(this.props.user) && this.state.patient) {
            this.loadPhotos(this.state.patient.id);
        }
        this.loadPatients()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (userAcceptedTerms(this.props.user) && this.props.user !== prevProps.user && this.state.patient) {
            this.loadPhotos(this.state.patient.id);
        }
    }

    loadPatients= () => {
        axios.get(`/api/patients/`)
            .then(response => {
                this.setState({patients: response.data})
            })
    };

    onSelected = (selected) => {
        this.onPatientSelected(selected);
        this.loadPhotos(selected.value);
    };

    loadPhotos = (patientId) => {
        this.setState({isLoading: true});
        axios.get('/api/photos/', {
            params: {patient_id: patientId}
        }).then(response => {
            if (response.data.length > 0) {this.setState({photos: response.data, isLoading: false})}
                else this.setState({isLoading: false})
            })
    };

    onCompute = () => {
        this.setState({isLoading: true});
        axios.post('/api/results/', {
            patient_id: this.state.patient.id,
        }).then(response => {
            if (response.status === 200) {
                this.setState({gotResults: true, isLoading: false, results: response.data});
            }
        })
    };

    onShowResults = () => {
        this.props.history.push({pathname: '/results', state: {patient: this.state.patient}})
    };

    isPhotoReady = (photo) => photo.is_ready === true;
    isPhotoWithResult = (photo) => photo.result !== null;

    render() {
        if (this.props.user && !userAcceptedTerms(this.props.user)) {
            return <Redirect to='/terms'/>
        }
        const allPhotosUploaded = this.state.photos ? this.state.photos.every(this.isPhotoReady) : false;
        const allPhotosWithResult = this.state.photos ? this.state.photos.every(this.isPhotoWithResult) : false;

        return (
            <div>
                <PatientSelect getPatientValue={this.getPatientValue}
                               getPatientOptions={this.getPatientOptions}
                               onPatientSelected={this.onSelected}
                />
                {!this.state.isLoading
                    ? this.state.patient &&
                    <>
                        <Photos photos={this.state.photos} onFinish={this.loadPhotos} patient={this.state.patient}/>
                        {!allPhotosWithResult
                            ?
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!allPhotosUploaded}
                                onClick={this.onCompute}
                                className="compute-button"
                            >
                            Compute !
                            </Button>
                            :
                            <Button variant="contained"
                                    color="primary"
                                    className="profile-button-wrapper"
                                    onClick={this.onShowResults}>
                                Show Results
                            </Button>
                        }
                    </>
                    :
                    <CircularProgress  className='photosUpload-spinner' size={75}/>
                }
                {this.state.gotResults && Object.keys(this.state.results).length !== 0
                    ? <Redirect push to={{pathname: '/results', state: { results: this.state.results, patient: this.state.patient }}}/>
                    : null
                }
            </div>
        );
    }
}

export default withRouter(PhotosUpload);
