import React, {Component} from 'react';
import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";
import Login from './containers/Auth/Login'
import Main from './containers/Main/Main'
import {PrivateRoute} from './util/PrivateRoute';


class App extends Component {
    render() {
        return (
            <div className='main-div-wrapper'>
                <Switch>
                    <Route path='/login' component={Login}/>
                    <PrivateRoute path='/:page(home|terms|profile|patients|photos|results|logout)' component={Main}/>
                    <Redirect to='/login'/>
                </Switch>
            </div>
        )
    }


}

export default App;
