import React, { useState } from 'react';
import Cookies from 'js-cookie';
import axios from "../../util/AxiosWithAuth";

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Auth} from "../../util/Auth";
import {Redirect, withRouter} from "react-router-dom";
import Link from "@material-ui/core/Link";
import logo from '../../assets/images/logo_notext.png';
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const defaultPath = '/home';

const Login = (props) => {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const login = (e) => {
        e.preventDefault();
        axios.post('/api/login/', {
            username: username,
            password: password,
        }, {
            headers: {'X-CSRFToken': Cookies.get('csrftoken')}
        })
            .then(result => {
                if (result.status === 200) {
                    Auth.saveCredentials(result.data['token']);
                    if (!result.data['terms_accepted']) {
                        props.history.push('/terms')
                    } else {
                        props.history.push(defaultPath)
                    }
                }
            })
            .catch(error => {
                let error_text = error.response.statusText + '. ' + Object.values(error.response.data);
                Object.keys(error.response.data).forEach( key => {
                    if (['username', 'password'].includes(key)) {
                        error_text = key + ' - ' + error.response.data[key]
                    }
                });
                if (error.response.status === 500) {
                    error_text = error.response.statusText + '. Something is wrong with server'
                }
                setError(error_text)
            })
    }

    if (Auth.isLogged()) {
        return <Redirect to={defaultPath}/>
    }

    let errorAlert = null;
    if (error) {
        errorAlert = (
            <div style={{color: '#FF1F1F', fontSize: '85%'}}>
                {error}
            </div>
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container spacing={2} style={{width: "80vw"}}>
                    <Grid item xs={12} sm={6} style={{textAlign: "right", margin: "auto"}}>
                        <Typography variant="h1" className="login-logo-text">Digital</Typography>
                        <Typography variant="h1" className="login-logo-text">Effective</Typography>
                        <Typography variant="h1" className="login-logo-text">Orthodontics</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <img src={logo} alt="HeaderLogo" className="login-logo" />
                    </Grid>
                </Grid>
                <Typography component="h1" variant="h5">
                    Log in to your account
                </Typography>
                <form className={classes.form} onSubmit={(e) => login(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="ID"
                        name="username"
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!username || !password}
                        type="submit"
                    >
                        Log in
                    </Button>
                    <Typography variant="h6">
                        Don’t have an account yet?
                    </Typography>
                    <Typography variant="subtitle1">
                        <Link href="mailto:info@takeitearly.com" >
                            Contact Us
                        </Link>
                    </Typography>
                </form>
            </div>
            {errorAlert}
        </Container>
    );
}

export default withRouter(Login)
