import React from 'react';
import TextField from "@material-ui/core/TextField";
import {DatePicker} from '@material-ui/pickers';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import {detailLabel} from "../../../util/common";


const PersonalDetail = (props) => {

    function getAge(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    let userInput = null;
    if (detailLabel[props.detail] != null) {
        userInput = <TextField
            label={detailLabel[props.detail]}
            value={props.value}
            onChange={event => props.userChange(props.detail, event.target.value)}
            margin="normal"
            variant="outlined"
            required
        />
    }

    if (props.detail === 'email') {
        userInput = <TextField
            label={detailLabel[props.detail]}
            value={props.value}
            onChange={event => props.userChange(props.detail, event.target.value)}
            type='email'
            margin="normal"
            variant="outlined"
            required
        />
    }

    let userAge = null;
    if (props.detail === 'birth_date') {
        userAge = (
            <>
                <br/>
                <TextField
                    label='Age'
                    value={getAge(props.value)}
                    margin="normal"
                    variant="outlined"
                    disabled
                />
            </>
        )
    }

    const Adornment = (
        {'endAdornment': <InputAdornment position="end">mm</InputAdornment>}
    )

    const detailsInMm = ['superior_left_central_incisor', 'inferior_left_central_incisor'];

    const numberDetails = ['age', 'overbite', 'overjet', 'superior_left_central_incisor', 'inferior_left_central_incisor'];
    if (numberDetails.includes(props.detail)) {
        userInput = <TextField
            label={detailLabel[props.detail]}
            value={props.value}
            onChange={event => props.userChange(props.detail, event.target.value)}
            type="number"
            style={{maxWidth: '230px'}}
            margin="normal"
            variant="outlined"
            InputLabelProps={detailsInMm.includes(props.detail) ? {style: {fontSize: 'smaller'}} : null}
            InputProps={detailsInMm.includes(props.detail) ? Adornment : null}
            required
        />
    }

    if (['primary_dentition', 'second_molars'].includes(props.detail)) {
        userInput = (
            <TextField
                select
                style={{minWidth: '230px'}}
                label={detailLabel[props.detail]}
                value={props.value}
                onChange={event => props.userChange(props.detail, event.target.value)}
                margin="normal"
                variant="outlined"
                required
            >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
            </TextField>
        )
    }

    if (props.detail === 'mixed_dentition') {
        userInput = (
            <TextField
                select
                style={{minWidth: '230px'}}
                label={detailLabel[props.detail]}
                value={props.value}
                onChange={event => props.userChange(props.detail, event.target.value)}
                margin="normal"
                variant="outlined"
                required
            >
                <MenuItem value={'early'}>Early</MenuItem>
                <MenuItem value={'late'}>Late</MenuItem>
            </TextField>
        )
    }

    if (props.detail === 'birth_date') {
        userInput = (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    autoOk
                    disableFuture
                    variant="inline"
                    inputVariant="outlined"
                    format="DD-MM-YYYY"
                    emptyLabel="DD-MM-YYYY"
                    id="date-picker-inline"
                    margin="normal"
                    label="Date of birth"
                    value={props.value}
                    onChange={date => props.userChange(props.detail, date.format('YYYY-MM-DD'))}
                />
            </MuiPickersUtilsProvider>
        )
    }

    return (
        <div>
            {userInput}
            {userAge}
        </div>
    );
};

export default PersonalDetail;
