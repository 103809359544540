import React from 'react';
import HeaderLink from "./HeaderLink/HeaderLink";
import './Header.css'
import Logo from "../Logo/Logo";
import ContactLink from "./ContactLink/ContactLink";


const Header = (props) => {
    return (
        <nav className='header-main'>
            <ul className='HeaderItems'>
                <Logo/>
                <ContactLink link="mailto:info@takeitearly.com">Contact Us</ContactLink>
                <HeaderLink link="/profile">Profile</HeaderLink>
                <HeaderLink link="/patients">Patients</HeaderLink>
                <HeaderLink link="/photos">Analysis</HeaderLink>
                <HeaderLink link="/results">Results</HeaderLink>
                <HeaderLink link="/logout">Log out</HeaderLink>
            </ul>
        </nav>
    );
};

export default Header;
