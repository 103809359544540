export function userAcceptedTerms(user){
    return (user && user.terms_accepted) ? true : false;
}

export function resizeImg(width, height, maxSize, maxHeight=null) {
    const minSize = 150;
    let newWidth = width;
    let newHeight = height;
    if (width >= height) {
        if (width > maxSize) {
            newHeight = height * maxSize / width;
            newWidth = maxSize;
        } else if (width < minSize) {
            newHeight = height * minSize / width;
            newWidth = minSize;
        }
    } else {
        maxSize = maxHeight ? maxHeight : maxSize;
        if (height > maxSize) {
            newWidth =  width * maxSize / height;
            newHeight = maxSize;
        } else if (height < minSize) {
            newWidth =  width * minSize / height;
            newHeight = minSize;
        }
    }
    return [newWidth, newHeight]
}

export function onUserFieldChange(field, value){
    const updatedUser = {...this.state.user};
    updatedUser[field] = value;
    this.setState({
        user: updatedUser,
    });
}

export function onPatientFieldChange(field, value){
    const updatedPatient = {...this.state.patient};
    updatedPatient[field] = value;
    this.setState({
        patient: updatedPatient,
    });
}

export function getFullName(patient) {
    return `${patient.first_name } ${patient.last_name}`.trim()
}

export function getPatientValue() {
    if (this.state.patient === null)
        return null;
    return {
        value: this.state.patient.id,
        label: getFullName(this.state.patient),
    }
}

export function getPatientOptions() {
    if (this.state.patients === [])
        return [];
    return this.state.patients.map(patient => {
        return {
            value: patient.id,
            label: getFullName(patient),
        }
    });
}

export function onPatientSelected(selected) {
    const patientId = selected === null ? null : selected.value;
    const patientSelected = this.state.patients.find(patient => patient.id === patientId);
    this.setState({patient: patientSelected});
}

export const newPatient = {
    "id": 0,
    "first_name":"",
    "last_name":"",
    "age":'',
    "overbite":'',
    "overjet":'',
    "superior_left_central_incisor":'',
    "inferior_left_central_incisor":'',
    "primary_dentition":true,
    "second_molars":true,
    "mixed_dentition":'early',
}

export const detailLabel = {
    title: 'Title',
    first_name: 'Name',
    last_name: 'Surname',
    university: 'University',
    birth_date: 'Date of birth',
    email: 'E-mail',
    age: 'Age',
    overbite: 'Overbite',
    overjet: 'Overjet',
    superior_left_central_incisor: 'Superior left central incisor',
    inferior_left_central_incisor: 'Inferior left central incisor',
    primary_dentition: 'Primary dentition',
    second_molars: 'Second molars',
    mixed_dentition: 'Mixed dentition',
};
