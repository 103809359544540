import React, {useState} from 'react';
import ReactS3Uploader from 'react-s3-uploader'
import Skeleton from '@material-ui/lab/Skeleton';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import './Photo.css'
import axios from "../../../util/AxiosWithAuth";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {resizeImg} from "../../../util/common";
import CircularProgress from "@material-ui/core/CircularProgress";


const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

const GreenFormControlLabel = withStyles({
    root: {
        marginRight: '0px',
    },
    label: {
        color: green[400],
        '&$disabled': {
            color: green[600],
        },
    },
    disabled: {},
}, {name: 'GreenFormControlLabel'})(props => <FormControlLabel color="default" {...props} />);

const maxWidth = 150;
const maxHeight = 200;

const Photo = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const resizePhoto = (file, next) => {
        const img = new Image ();
        const canvas = document.createElement ('canvas');
        const ctx = canvas.getContext ('2d');
        img.onload = () => {
            let newWidth, newHeight;
            const maxSize = 600;
            let { width, height } = img;
            [newWidth, newHeight] = resizeImg(width, height, maxSize);
            canvas.width = newWidth;
            canvas.height = newHeight;
            ctx.drawImage (img, 0, 0, newWidth, newHeight);
            next (new File ([dataURItoBlob (canvas.toDataURL (file.type, 0.9))], file.name, {type: file.type}));
        };
        img.src = URL.createObjectURL (file);
    }

    function dataURItoBlob(dataURI, format) {
        const binary = atob (dataURI.split (',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) { // eslint-disable-line
            array.push (binary.charCodeAt (i));
        }
        return new Blob ([new Uint8Array (array)], {type: format});
    }

    function getSignedUrl(file, callback) {
        const params = {
            objectName: file.name,
            contentType: file.type,
            photo_type: props.photo.photo_type,
            patient_id: props.patient.id,
        };
        axios.get('/api/s3sign/', {params: params})
            .then(response => {
                setIsLoading(true);
                callback(response.data)
            })
    }

    const onFinish = (signResult) => {
        axios.post ('/api/photo/', {
            url: signResult.signedUrl,
            photo_type: props.photo.photo_type,
            patient_id: props.patient.id,
        }).then (response => {
            setIsLoading(false);
            if (response.status === 200) {props.onFinish(props.patient.id)}
        })
    }

    let width, height;
    [width, height] = resizeImg(props.photo.width, props.photo.height, maxWidth, maxHeight);

    return (
        <Card className='photoCard'>
            <CardHeader className='photo-card-header'
                title={
                    <Typography variant='h5'>
                        {props.photo.photo_type}
                    </Typography>
                }
            />
            {isLoading
                ? <div style={{margin: '75px 0'}}><CircularProgress /></div>
                : props.photo.url
                    ? <img style={{width: `${width}px`, height: `${height}px`, margin: '10px'}} src={props.photo.url} alt="Front"/>
                    : <Skeleton variant="rect" width={maxWidth} height={maxHeight} style={{margin: '10px', display: 'inline-block'}} />
            }
            <Grid container spacing={2}>
                {!props.photo.result
                    ?
                    <>
                        <Grid item xs={12}>
                            <ReactS3Uploader
                                preprocess={resizePhoto}
                                getSignedUrl={getSignedUrl}
                                accept="image/*"
                                uploadRequestHeaders={{}}
                                onFinish={onFinish}
                                scrubFilename={(filename) => filename.replace (/[^\w\d_\-.]+/ig, '')}
                                autoUpload={true}
                                style={{color:'transparent', width:'140px'}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} className='photo-sm-grid'>
                            <Button variant="contained"
                                    style={{width: '50%', minWidth: '100px'}}
                                    disabled={!props.photo.url}
                                    color={props.photo.is_ready ? "primary" : "secondary"}
                                    onClick={() => props.handlePhoto (props.photo)}>
                                {props.photo.is_ready ? "Edit" : "Start !"}
                            </Button>
                        </Grid>
                    </>
                    : null
                }
                <Grid item xs={12} sm={12} md={6} className='photo-sm-grid'>
                    <GreenFormControlLabel
                        control={<GreenCheckbox checked={props.photo.is_ready ? true : false} disabled color="primary" style={{marginLeft: '10px'}}/>}
                        label={props.photo.is_ready ? 'Ready!' : 'Not ready'}
                    />
                </Grid>
            </Grid>


        </Card>
    );
}

export default Photo;
