import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './PhotoSketchField.css';

export default class CanvasComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDown: false,
        }
    }

    componentDidMount() {
        const canvas = ReactDOM.findDOMNode(this.refs.canvas);
        const ctx = canvas.getContext("2d");
        canvas.width = this.props.photo ? this.props.photo.width : 0;
        canvas.height = this.props.photo ? this.props.photo.height : 0;
        if (this.props.photo && Object.values(this.props.photo.markers).length > 0) {
            Object.values(this.props.photo.markers).forEach(coords=>{
                const marker = this.drawMarker(coords.x, coords.y);
                ctx.fillStyle = "red";
                ctx.fill(marker);
            });
        }
        // this.drawBackground(ctx);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.markersCount < prevProps.markersCount) {
            this.redrawMarkers();
        }
    }

    handleMouseDown = (event) => {
        if (this.props.step < this.props.maxSteps && this.props.step === this.props.markersCount) {
            this.setState({isDown: true}, () => {
                const canvas = ReactDOM.findDOMNode(this.refs.canvas);
                const ctx = canvas.getContext("2d");
                const x = event.offsetX;
                const y = event.offsetY;
                const marker = this.drawMarker(x, y);
                ctx.fillStyle = "red";
                ctx.fill(marker);
                this.props.setMarkerState(x, y);
            })
        }
    };

    handleMouseUp = () => {
        this.setState({isDown: false})
    };

    // drawBackground = (ctx) => {
    //     const background = new Image();
    //     background.src = this.props.photo ? this.props.photo.url : '';
    //     background.onload = function () {
    //         ctx.drawImage(background, 0, 0);
    //     }
    // }

    drawMarker = (x, y) => {
        const circle = new Path2D();
        circle.moveTo(x, y);
        circle.arc(x, y, 5, 0, 2 * Math.PI);
        return circle
    };

    redrawMarkers = () => {
        const canvas = ReactDOM.findDOMNode(this.refs.canvas);
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        Object.values(this.props.photo.markers).forEach(coords=>{
            const marker = this.drawMarker(coords.x, coords.y);
            ctx.fillStyle = "red";
            ctx.fill(marker);
        });
    };


    render() {
        return (
                <canvas id="canvas" ref="canvas"
                        style={{backgroundImage: this.props.photo ? `url(${this.props.photo.url})` : ''}}
                        className="edit-photo-border-canvas"
                        onMouseDown={e => {this.handleMouseDown(e.nativeEvent)}}
                        onMouseUp={e => {this.handleMouseUp(e.nativeEvent)}}
                />
        );
    }
}
