import React, {Component} from 'react';
import Photo from "./Photo/Photo";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import "./Photos.css"


class Photos extends Component {
    handlePhoto = (photo) => {
        this.props.history.push({pathname: `/photos/edit/${photo.photo_type}`, state: {patient: this.props.patient}})
    };

    render() {
        return (
            <div className='photoContainer'>
                <Grid container spacing={2} justify="center">
                    <Grid item  sm={1}/>
                    <Grid container item  direction="row" justify="center" alignItems="center" sm={10} lg={10} spacing={2}>
                    {this.props.photos && this.props.photos.map((photo, index) => {
                        return (
                            <Grid item xs={12} sm={6} lg={3} key={'gridItem'+index} >
                                <Photo key={index} photo={photo} handlePhoto={this.handlePhoto}
                                       onFinish={this.props.onFinish}
                                       patient={this.props.patient}
                                />
                            </Grid>
                        )
                    })}
                    </Grid>
                    <Grid item  sm={1}/>
                </Grid>
            </div>
        );
    }
}

export default withRouter(Photos);
