import React from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from '@material-ui/core/styles';


const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
});


const ModelTable = (props) => {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">Type {props.idx}</StyledTableCell>
                        <StyledTableCell align="center">{props.tableData['type']}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center">Device</StyledTableCell>
                        <StyledTableCell align="center">{props.tableData['device']}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="center">Model</StyledTableCell>
                        <StyledTableCell align="center">{props.tableData['model']}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="center">Size {props.idx}</StyledTableCell>
                        <StyledTableCell align="center">{props.tableData['size']}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell align="center">Size End</StyledTableCell>
                        <StyledTableCell align="center">{props.tableData['size_end']}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
};

export default ModelTable;
