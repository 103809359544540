import React from "react";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import './PatientSelect.css'


const PatientSelect = (props) => {
    return (
        <>
            <Typography variant="h5" style={{marginBottom: '20px'}}>
                Please select patient to manage
            </Typography>
            <Select maxMenuHeight={150}
                    placeHolder={"Select patient"}
                    menuPortalTarget={document.body}
                    className="patients-select"
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    value={props.getPatientValue()}
                    options={props.getPatientOptions()}
                    onChange={selected => props.onPatientSelected(selected)}
            />
        </>
    );
};

export default PatientSelect;
