import React from 'react';
import Header from "../../components/UI/Header/Header";
import {Auth} from "../../util/Auth";
import './Layout.css'


const Layout = (props) => {
    return (
        <>
            {Auth.isLogged() ? <Header/> : null}
            <main>
                <div className='main-div-wrapper'>
                {props.children}
                </div>
            </main>
        </>
    );
};

export default Layout;
